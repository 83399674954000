import logo from './assets/logo.svg';
import logoBg from './assets/bg-logo.png';
import servicesIcon from './assets/icon.png';
import servicesIcon2 from './assets/icon-2.png';
import servicesIcon3 from './assets/icon-3.png';
import servicesIcon4 from './assets/icon-4.png';
import servicesIcon5 from './assets/icon-5.png';
import servicesIcon6 from './assets/icon-6.png';
import servicesIcon7 from './assets/icon-7.png';
import servicesElement from './assets/services-element.png';
import arrow from './assets/arrow.png';
import instagram from './assets/instagram.png';
import telegram from './assets/telegram.png';
import youtube from './assets/youtube.png';
import mail from './assets/mail.png';

function App() {
  return (
    <div className="wrapper">
      <div className="header">
        <img className="header__logo" src={logo} alt="" />

        <div className="header__info">
          <p>
            PR <span></span> Creative <span></span> Event
          </p>
        </div>
      </div>

      <div className="about">
        <h2 className="about__title">О нас</h2>
        <div className="container">
          <div className="about__text">
            Именно у нас вы найтете симбиоз PR и Event Команда Event поможет реализовать и провести
            мероприятия любого масштаба и сложности <br /> <br />
            Команда PR станет ндежным партнером в коммуникациях и поможет грамотно рассказать и
            показать всем о том как проходило Ваше мероприятие <br />
            <br />
            Мы любим креатив и применяем его в решении всех задач. Можете быть уверенны в том, что
            именно мы вам предложим самые необычные, яркие и запоминающиеся решения во всех вопросах
          </div>
          <div className="about__bg">
            <img src={logoBg} alt="" />
          </div>
        </div>
      </div>

      <div className="services">
        <h2 className="services__title">Услуги</h2>
        <div className="container">
          <div className="services__inner">
            <div className="services__content-top">
              <div className="services__item">
                <img className="services__item-icon" src={servicesIcon} alt="" />
                <p className="services__item-title">Разработка PR-стратегии</p>
              </div>
              <div className="services__element one">
                <img src={servicesElement} alt="" />
              </div>
              <div className="services__item">
                <img className="services__item-icon" src={servicesIcon2} alt="" />
                <p className="services__item-title">Организация меропрятий любого масштаба </p>
              </div>
              <div className="services__element two">
                <img src={servicesElement} alt="" />
              </div>

              <div className="services__item">
                <img className="services__item-icon" src={servicesIcon3} alt="" />
                <p className="services__item-title">Установление и развитие отношений со СМИ</p>
              </div>
            </div>

            <div className="services__content-bottom">
              <div className="services__item">
                <img className="services__item-icon" src={servicesIcon4} alt="" />
                <p className="services__item-title">С блоггерами и лидерами мнений</p>
              </div>

              <div className="services__item">
                <img className="services__item-icon" src={servicesIcon5} alt="" />
                <p className="services__item-title">Продюсирование мероприятий</p>
              </div>
              <div className="services__element three">
                <img src={servicesElement} alt="" />
              </div>

              <div className="services__item">
                <img className="services__item-icon" src={servicesIcon6} alt="" />
                <p className="services__item-title">Оформление мероприятий </p>
              </div>

              <div className="services__item">
                <img className="services__item-icon" src={servicesIcon7} alt="" />
                <p className="services__item-title">Кризисные коммуникации</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="order">
        <div className="container">
          <div className="order__inner">
            <h2 className="order__title">
              Оставьте заявку прямо сейчас, чтобы начать работу над вашим проектом
            </h2>
            <img className="order__arrow" src={arrow} alt="" />
            <a href='https://t.me/Statusagencydirector' className="order__btn">оставить заявку</a>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="container">
          <div className="footer__inner">
            <a href="tel:+998200015858" className="footer__btn">
              Позвонить
            </a>
            <div className="footer__socila">
              <a href="https://www.instagram.com/status.creative.uz?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==">
                <img src={instagram} alt="" />
              </a>
              <a href="https://t.me/Statusagencydirector">
                <img src={telegram} alt="" />
              </a>
              <a href="https://youtube.com/@StatusMediaAgency?si=OFyTSxjFJ4js9qw2">
                <img src={youtube} alt="" />
              </a>
              <a href="mailto:statusmediaagency@gmail.com">
                <img src={mail} alt="" />
              </a>
            </div>
            <img className="footer__logo" src={logo} alt="" />
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
